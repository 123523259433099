
.footer {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 0;
}

.footerItem {
    padding: 20px calc(3%);
}

.footerItem p {
    font-size: 12px;
}