body {
    font-size: 18px;
    font-family: "San Francisco", sans-serif;
    font-style: normal;
}

h2 {
    margin-bottom: 20px!important;
}

button {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: inline-block;

    color: #fff;
    font-size: inherit;
    font-weight: bold;
    text-align: center;

    padding: 19px;
    margin-top: 15px;
    margin-bottom: 10px;


    border-radius: 8px;
    border: 2px solid #ffffff;
    background: linear-gradient(to right, rgba(74, 108, 30, 1) 0%, rgba(109, 151, 48, 1) 100%);
    text-decoration: none;

    /*transition: all 0.8s ease;*/
}
button:hover {
    cursor: pointer;
    background: rgb(109, 151, 48);
}

button:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(90deg,rgba(255,255,255,0.1) 10%,rgba(255,255,255,0.2) 20%,rgba(255,255,255,0.6));
    width: 20px;
    -webkit-transform: skewX(-45deg);
    left: -20%;
    -webkit-animation-name: blinker;
    animation-duration: 7s;
    animation-iteration-count: infinite;
}

.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.formInput {
    position: relative;

    width: 34%;
    font-size: 16px;
    padding: 20px calc(5%);
    border-radius: 8px;
    border: 2px solid #4A6C1E;
    outline: none;

    margin: 10px 0;
}

.inputError{
    color: red;
    font-weight: bold;
    bottom: -25px;
    font-size: 18px;
}

.sent {
    font-size: 14px;
    color: green;
    margin: 0 auto;
    text-align: center;
    text-shadow: 1px 1px white;
    background-color: #ffffffcf;
    border-radius: 8px;
}

@media (max-width: 600px) {
    .formInput {
        width: 100%;
    }
    h2 {
        font-size: 1.3em;
    }
}

.agreement {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

hr{
    height: 2px;
    background: white;
    border: none;
}

@keyframes blinker {
    40%,to{left:120%}
    50%,to{left:120%}
    60%,to{left:120%}
}


/* --- MODAL CSS --- */

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.2em;
}

.wrapper img {
    width: 100%;
    height: auto;
}

.wrapper-images {
    position: relative;
    flex: auto;
    width: 25%;
    flex-basis: 24%;
}

/*.wrapper-images::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: url('../src/assets/commonAssets/zoom.svg') no-repeat;
    z-index: 1;
}*/

.wrapper-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 150px);
    grid-gap: 20px;
    margin-bottom: -20px;
}

.wrapper-grid img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.wrapper-grid-images:first-child {
    grid-column: 1/3;
    grid-row: 1/3;
}

/* modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.541);
    display: flex;
    align-items: center;

    z-index: 100;
}
.overlay img {
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    flex-basis: 16%;
    margin: -50% auto;

    max-height: 80%;
}
.overlay img.landscape {
    height: auto;
    max-height: 100%;
    width: inherit;
    max-width: 100%;
}
.overlay > span {
    position: absolute;
    top: calc(5%);
    right: calc(5%);
    font-size: 50px;
    color: white;
    cursor: pointer;
    font-weight: 400;
    text-shadow: -1px 2px 10px black;
}
.overlay-arrows_left {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    border-radius: 100%;

    cursor: pointer;
}

.overlay-arrows_left svg, .overlay-arrows_right svg {
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background-color: #5c5c5c;
    color: white;
    padding: 5px;
}

.overlay-arrows_right {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    border-radius: 100%;

    cursor: pointer;
}

/* --- FONTS --- */

/** Ultra Thin */
@font-face {
    font-family: "San Francisco";
    font-weight: 100;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-ultralight-webfont.woff");
    font-display: swap;
}

/** Thin */
@font-face {
    font-family: "San Francisco";
    font-weight: 200;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.woff");
    font-display: swap;
}

/** Regular */
@font-face {
    font-family: "San Francisco";
    font-weight: 400;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
    font-display: swap;
}

/** Medium */
@font-face {
    font-family: "San Francisco";
    font-weight: 500;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff");
    font-display: swap;
}

/** Semi Bold */
@font-face {
    font-family: "San Francisco";
    font-weight: 600;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff");
    font-display: swap;
}

/** Bold */
@font-face {
    font-family: "San Francisco";
    font-weight: 700;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
    font-display: swap;
}